import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import PropTypes from "PropTypes";

import { useParams } from "react-router-dom";
import Loading from "../components/loading/loading";
import OrderDetailFinish from "../components/order_detail/footer/order_detail_finish";
import OrderDetailsControlsWrapper from "../components/order_detail/footer/wrapper";
import { OrderDetailContextProvider } from "../components/order_detail/order_detail_context";
import OrderDetailItems from "../components/order_detail/order_detail_items";
import { VendorsOrder } from "../entities/order.entity";
import { getOrder } from "../services/vendor/orders";

function OrderFinish() {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<VendorsOrder>();
  const { orderId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function getDataOrder() {
      setLoading(true);
      const token = localStorage.getItem("auth-token");
      const response = await getOrder(token, orderId);
      if (response) {
        setOrder(response);
      }
      setLoading(false);
    }
    getDataOrder();
  }, []);

  return (
    <>
      <div className="p-10">
        <h1 className="text-3xl font-medium">{`${t("order_detail.title")} ${
          order ? order.id : ""
        }`}</h1>
        {order === undefined || loading === true ? (
          <Loading />
        ) : (
          <>
            <OrderDetailContextProvider InitialOrder={order}>
              <>
                <OrderDetailItems order={order} readOnly={false} />
                <OrderDetailsControlsWrapper>
                  <OrderDetailFinish></OrderDetailFinish>
                </OrderDetailsControlsWrapper>
              </>
            </OrderDetailContextProvider>
          </>
        )}
      </div>
    </>
  );
}

export default OrderFinish;
