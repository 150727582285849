export function Download(fileBlob, filename, download = true) {
  const element = document.createElement("a");
  const file = URL.createObjectURL(fileBlob);
  element.setAttribute("href", file);
  if (download) {
    element.setAttribute("download", filename);
  } else {
    element.setAttribute("target", "_blank");
  }
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
