import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJson from "./en.json";
import esJson from "./es.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enJson,
      },
      es: {
        translation: esJson,
      },
    },
    fallbackLng: "en",
    whitelist: ["en", "es"],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
  });

export const changeLanguage = (nextLanguage, navigate) => {
  let currentPathname = window.location.pathname;
  currentPathname = currentPathname.replace(/(?<=\/)(.*?)(?=\/)/, nextLanguage);
  if (currentPathname === "/") {
    currentPathname = `${currentPathname}${nextLanguage}/`;
  }
  i18n.changeLanguage(nextLanguage);
  navigate(currentPathname, { replace: true });
};

export default i18n;
