import React, { useContext } from "react";
import { OrderStatuses } from "../../../entities/order.entity";
import { VendorsOrderDetailSize } from "../../../entities/order_detail.entity";
import { OrderDetailContext } from "../order_detail_context";
import "./size_selector.scss";

export default function SizeSelector({
  sizes,
  readOnly,
}: {
  sizes: VendorsOrderDetailSize[];
  readOnly: boolean;
}) {
  const { order, toggleSizeActivation, changeSizeSelectedQuantity } =
    useContext(OrderDetailContext);

  const changeSizeQuantity = (
    size: VendorsOrderDetailSize,
    quantity: number
  ) => {
    if (
      order.estado === OrderStatuses.NEW ||
      order.estado === OrderStatuses.DECLINED ||
      readOnly
    ) {
      return;
    }
    if (size.maxSelectQuantity >= quantity && quantity >= 0) {
      changeSizeSelectedQuantity(size.id, quantity);
    }
  };

  const handleSelect = (size: VendorsOrderDetailSize, isSelected: boolean) => {
    toggleSizeActivation(size.id, isSelected);
  };

  return (
    <>
      <div className={`size_selector_wrapper`}>
        {sizes.map((size) => (
          <div
            key={size.primaryText}
            className={`size_selector ${size.isSelected ? "active" : ""} ${
              size.discontinued && "discontinued"
            }`}
            onClick={(e) => {
              if (
                order.estado != OrderStatuses.NEW &&
                order.estado != OrderStatuses.DECLINED &&
                !readOnly
              ) {
                handleSelect(size, !size.isSelected);
              }
            }}
          >
            <p className={"size_name"}>{size.primaryText}</p>
            <p className={"size_inventory"}>
              {size.secondaryText.info}
              <span>{size.secondaryText.value}</span>
            </p>
            <p className={"size_price"}>
              <strong>$ {(size.price || 0).toFixed(2)}</strong>
            </p>
            {order.estado != OrderStatuses.NEW && size.isSelected && (
              <div className={"quantity_controls"}>
                <button
                  className={"quantity_buttons"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeSizeQuantity(size, size.selectedQuantity - 1);
                  }}
                >
                  -
                </button>
                <input
                  className={"quantity"}
                  type="number"
                  min="0"
                  value={size.selectedQuantity}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    changeSizeQuantity(size, Number(e.target.value));
                  }}
                />
                <button
                  className={"quantity_buttons"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeSizeQuantity(size, size.selectedQuantity + 1);
                  }}
                >
                  +
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
