import React, { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import i18n, { changeLanguage } from "../resources/translation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header/header";
import { me } from "../services/auth/auth";
export default function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState();

  const getUser = useCallback(async () => {
    const response = await me();
    setUser(response);
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/es") && i18n.language === "es") {
      changeLanguage("es", navigate);
    }
    if (!location.pathname.includes("/en") && i18n.language === "en") {
      changeLanguage("en", navigate);
    }
  }, [location.pathname, navigate]);
  useEffect(() => {
    getUser();
  }, [getUser]);
  return (
    <>
      <Header isAuthenticated={user ? true : false} user={user} />
      <Outlet />
    </>
  );
}
