import React from "react";

export default function OrderDetailsControlsWrapper({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <>
      <div className="lg:sticky flex items-center py-6 bottom-0 border-t border-gray-400 bg-white flex-col">
        <form className="flex items-center justify-between w-full">
          {children}
        </form>
      </div>
    </>
  );
}
