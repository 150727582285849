import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Product from "./product";
import _ from "lodash";
import { VendorsOrderDetailProduct } from "../../entities/order_detail.entity";
import { OrderDetailContext } from "./order_detail_context";
import { formatCurrency } from "../../common/formatCurrency";
import { OrderStatuses } from "../../entities/order.entity";
import {
  getOrderExportWithDetails,
  getOrderPrintVersion,
} from "../../services/vendor/orders";
import { Download } from "../../common/download";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function OrderDetailItems({ readOnly }: { readOnly: boolean }) {
  const { t } = useTranslation();
  const [orderDetailProducts, setOrderDetailProducts] = useState<any>([]);
  const navigate = useNavigate();

  const { order, selectAll, changeSelectAll } = useContext(OrderDetailContext);
  const handleExportDetails = async () => {
    const token = localStorage.getItem("auth-token");
    const orderPrintWithDetailsRespnose = await getOrderExportWithDetails(
      token,
      order.id
    );
    // eslint-disable-next-line no-debugger
    Download(orderPrintWithDetailsRespnose, `export_details_${order.id}.xls`);
  };
  const handlePrintVersion = async () => {
    const token = localStorage.getItem("auth-token");
    const orderPrintWithDetailsRespnose = await getOrderPrintVersion(
      token,
      order.id
    );
    // eslint-disable-next-line no-debugger
    Download(
      orderPrintWithDetailsRespnose,
      `order_details_${order.id}.pdf`,
      false
    );
  };
  useEffect(() => {
    try {
      const detailProducts = _.chain(order.order_details)
        .groupBy((detail) => detail.item_product.Description.split(" Size")[0])
        ?.map((productGroup) => {
          return {
            id: productGroup[0].item_product.item,
            MPN: productGroup[0].item_product.MPN,
            Description: productGroup[0].item_product.Description,
            coleccion: productGroup[0].item_product.coleccion,
            selectAll: selectAll,
            sizes: productGroup.map((product) => ({
              quantity_accepted: product.qty_confirmada,
              quantity_ordered: product.qty_pedida,
              quantity_dispatched: product.qty_despachada,
              quantity_pending: product.qty_pendiente,
              item: product.item_product,
              isSelected: product.isSelected,
              selectedQuantity: product.selectedQuantity,
            })),
          };
        })
        .value();
      if (setOrderDetailProducts) {
        setOrderDetailProducts(detailProducts);
      }
    } catch (error) {
      toast.error(t("order_detail.error_listing"));
      navigate("/");
    }
  }, [order, selectAll]);

  return (
    <form className="mt-12">
      <div className="min-h-screen">
        <div
          className={
            "flex mb-1 xs:flex-col-reverse lg:flex-row lg:justify-between p-4"
          }
        >
          <div className="flex xs:flex-col-reverse lg:flex-row lg:justify-start">
            <div className="flex lg:mr-8 xs:self-end xs:text-2xl lg:text-lg">
              {order.porcen_cant_depachado.toFixed(2)} % (
              {order.cantidad_aceptada - order.cantidad_pendiente} /{" "}
              {order.cantidad_aceptada})
              <span className={"xs:hidden lg:block lg:ml-1"}>
                {" "}
                {t("order_detail.percent_quantity")}
              </span>
            </div>
            <div className="flex lg:mr-8 xs:self-end xs:text-2xl lg:text-lg">
              {order.porcen_cost_despachado.toFixed(2)} % ( ${" "}
              {formatCurrency(
                order.costo_cantidad_aceptada - order.costo_pendiente
              )}{" "}
              / $ {formatCurrency(order.costo_cantidad_aceptada)})
              <span className={"xs:hidden lg:block lg:ml-1"}>
                {" "}
                {t("order_detail.percent_cost")}
              </span>
            </div>
          </div>
          <div className="flex xs:flex-col-reverse lg:flex-row lg:justify-start items-center">
            {order.estado != OrderStatuses.NEW && (
              <>
                <div className="flex lg:mr-8 xs:self-end xs:text-2xl lg:text-lg items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="select_all"
                      name="select_all"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        changeSelectAll(e.target.checked);
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="select_all"
                      className="font-medium text-gray-700"
                    >
                      {t("order_detail.select_all")}
                    </label>
                  </div>
                </div>

                <a
                  onClick={() => {
                    handleExportDetails();
                  }}
                  className={`
                      flex justify-center cursor-pointer
                      mr-2
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                      border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white 
                      bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500
                      `}
                >
                  {t("order_detail.export_details")}
                </a>
                <div
                  onClick={() => {
                    handlePrintVersion();
                  }}
                  className={`
                      flex justify-center cursor-pointer
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                      border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white 
                      bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500
                      `}
                >
                  {t("order_detail.print_version")}
                </div>
              </>
            )}
          </div>
        </div>
        <ul
          role="list"
          className="border-t border-b border-gray-200 divide-y divide-gray-200"
        >
          {orderDetailProducts?.map(
            (product: VendorsOrderDetailProduct, productIdx: number) => {
              return (
                <Product
                  key={productIdx}
                  product={product}
                  readOnly={readOnly}
                />
              );
            }
          )}
        </ul>
      </div>
    </form>
  );
}

OrderDetailItems.propTypes = {
  order: PropTypes.object,
};

export default OrderDetailItems;
