import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/protected";
import Login from "./pages/login";
import MainLayout from "./layouts/main";
import Dashboard from "./pages/dashboard";
import ExtLogin from "./pages/ext_login";
import OrderAccept from "./pages/order_accept";
import OrderFinish from "./pages/order_finish";
import OrderDetailReadOnly from "./pages/order_detail_read_only";

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="en">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="order/:orderId"
              element={
                <ProtectedRoute>
                  <OrderAccept />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="order/finish/:orderId"
              element={
                <ProtectedRoute>
                  <OrderFinish />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="order/read_only/:orderId"
              element={
                <ProtectedRoute>
                  <OrderDetailReadOnly />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="es">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="order/:orderId"
              element={
                <ProtectedRoute>
                  <OrderAccept />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="order/finish/:orderId"
              element={
                <ProtectedRoute>
                  <OrderFinish />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="order/read_only/:orderId"
              element={
                <ProtectedRoute>
                  <OrderDetailReadOnly />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="ext_login" element={<ExtLogin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
