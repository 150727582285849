/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../services/auth/auth";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    try {
      const loginResponse = await login(data);
      if (loginResponse.jwt) {
        localStorage.setItem("auth-token", loginResponse.jwt);
        toast.success(t("login.login_success.response"));
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t("login.title")}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            autoComplete="off"
          >
            <div>
              <label
                htmlFor="username"
                className={`block text-sm font-medium text-gray-700 ${
                  errors.username && errors.username.type !== undefined
                    ? "text-red-700"
                    : "text-gray-700"
                }`}
              >
                {t("login.user.label")}
              </label>
              <div className="mt-1">
                <input
                  {...register("username", { required: true })}
                  type="text"
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm${
                    errors.email && errors.email.type
                      ? "ring-red-500 border-red-500"
                      : "focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  }`}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className={`block text-sm font-medium text-gray-700 ${
                  errors.password && errors.password.type !== undefined
                    ? "text-red-700"
                    : "text-gray-700"
                }`}
              >
                {t("login.password.label")}
              </label>
              <div className="mt-1">
                <input
                  {...register("password", { required: true })}
                  type="password"
                  autoComplete="current-password"
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                    errors.password && errors.password.type
                      ? "ring-red-500 border-red-500"
                      : "focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  }`}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSubmit(onSubmit)}
              >
                {t("login.signin")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
