import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { UseFormRegister } from "react-hook-form";

export default function InputText({
  label,
  id,
  error,
  type,
  errorMessage,
  register,
}: {
  id: string;
  label: string;
  error?: boolean;
  errorMessage?: string;
  type: string;
  register: UseFormRegister<Record<string, string>>;
}) {
  return (
    <>
      <div className="w-full md:w-1/2 px-4 py-2">
        <label
          htmlFor={id}
          className={`block text-sm font-medium ${
            error === true ? "text-red-600" : "text-gray-700"
          }`}
        >
          {label} *
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            type={type}
            {...register(id)}
            className={`block w-full rounded-md  pr-10  focus:outline-none  sm:text-sm ${
              error === true
                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
                : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            }`}
            aria-invalid="true"
            aria-describedby={`${id}_error`}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {error === true && (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        {error === true && (
          <p className="mt-2 text-sm text-red-600" id={`${id}_error`}>
            {errorMessage}
          </p>
        )}
      </div>
    </>
  );
}
