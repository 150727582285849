import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";

export const login = async (data) => {
  const response = await middleware(axios.post(`${baseURL}/auth/login`, data));
  return response.data;
};
export const me = async () => {
  let token = localStorage.getItem("auth-token");
  const response = await middleware(
    axios.get(`${baseURL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
