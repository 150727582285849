import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { OrderStatuses, VendorsOrder } from "../../entities/order.entity";
import {
  VendorsOrderDetail,
  VendorsOrderDetailSize,
} from "../../entities/order_detail.entity";

interface OrderDetailContextType {
  order: VendorsOrder;
  setOrder?: Dispatch<SetStateAction<VendorsOrder>>;
  toggleSizeActivation: (sizeId: string, state: boolean) => void;
  changeSizeSelectedQuantity: (sizeId: string, quantity: number) => void;
  changeSelectAll: (value: boolean) => void;
  setDiscontinuedDetails: (
    discontinuedDetails: VendorsOrderDetailSize[]
  ) => void;
  selectAll: boolean;
}

const defaultOrder = {
  id: 0,
  consecutivo: 0,
  nItems: 0,
  valorTotal: 0,
  numero_entrega: 0,
  cantidad_pendiente: 0,
  porcen_cant_depachado: 0,
  porcen_cost_despachado: 0,
  cantidad_aceptada: 0,
  costo_cantidad_aceptada: 0,
  costo_pendiente: 0,
  items_dejados_vender: 0,
  fecha_pedido: new Date(),
  fecha_despacho: new Date(),
  fecha_requerida: new Date(),
  fecha_acept: new Date(),
  catalogo: "",
  proveedor: "",
  coment_venda: "",
  coment_prov: "",
  factura: "",
  respon_aceptado: "",
  responsableVenda: "",
  entregaParciales: "",
  editable: "",
  estado: "",
  pareto: "",
  vxs: "",
  pocAgotados: "",
  porcCumplim: "",

  order_details: [],
};

export const OrderDetailContext = createContext<OrderDetailContextType>({
  order: defaultOrder,
  toggleSizeActivation: () => console.log(""),
  changeSizeSelectedQuantity: () => console.log(""),
  changeSelectAll: () => console.log(""),
  setDiscontinuedDetails: () => console.log(""),
  selectAll: false,
});

export const OrderDetailContextProvider = ({
  InitialOrder,
  children,
}: {
  InitialOrder: VendorsOrder;
  children: React.ReactElement;
}) => {
  const [order, setOrder] = useState<VendorsOrder>(defaultOrder);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  useEffect(() => {
    const InitialOrderCopy = { ...InitialOrder };
    InitialOrderCopy.order_details.forEach((detail) => {
      detail.isSelected =
        InitialOrderCopy.estado === OrderStatuses.NEW ? true : false;
      detail.selectedQuantity =
        InitialOrderCopy.estado === OrderStatuses.NEW ? detail.qty_pedida : 0;
    });
    setOrder(InitialOrderCopy);
  }, []);

  const setDiscontinuedDetails = (
    discontinuedDetails: VendorsOrderDetailSize[]
  ) => {
    if (order) {
      const orderCopy = { ...order };
      discontinuedDetails.forEach((detailSize) => {
        const discountinuedDetailIdx = orderCopy.order_details.findIndex(
          (x) => x.item === detailSize.id
        );
        orderCopy.order_details[discountinuedDetailIdx].discontinued =
          detailSize.discontinued;
      });

      if (setOrder) {
        setOrder(orderCopy);
      }
    }
  };

  const toggleSizeActivation = (sizeId: string, state: boolean) => {
    const orderCopy = order;
    const detailIdx = order.order_details.findIndex((x) => x.item === sizeId);
    orderCopy.order_details[detailIdx].isSelected = state;

    if (setOrder) {
      setOrder({ ...orderCopy });
    }
  };

  const changeSizeSelectedQuantity = (sizeId: string, quantity: number) => {
    if (order) {
      const orderCopy = { ...order };
      const detailIdx = order.order_details.findIndex((x) => x.item === sizeId);
      orderCopy.order_details[detailIdx].selectedQuantity = quantity;

      if (setOrder) {
        setOrder(orderCopy);
      }
    }
  };

  const selectAllOnChangeQuantity = () => {
    if (order) {
      const orderCopy = { ...order };
      orderCopy.order_details.forEach((detail) => {
        detail.lastSelectedQuantity = detail.selectedQuantity;
        detail.selectedQuantity = detail.qty_pendiente;
      });

      if (setOrder) {
        setOrder(orderCopy);
      }
    }
  };
  const selectAllOffChangeQuantity = () => {
    if (order) {
      const orderCopy = { ...order };
      orderCopy.order_details.forEach((detail) => {
        detail.selectedQuantity = detail.lastSelectedQuantity;
      });

      if (setOrder) {
        setOrder(orderCopy);
      }
    }
  };

  const changeSelectAll = (selected: boolean) => {
    if (selected) {
      selectAllOnChangeQuantity();
      setSelectAll(true);
    } else {
      selectAllOffChangeQuantity();
      setSelectAll(false);
    }
  };

  return (
    <OrderDetailContext.Provider
      value={{
        order,
        setOrder,
        toggleSizeActivation,
        changeSizeSelectedQuantity,
        selectAll,
        changeSelectAll,
        setDiscontinuedDetails,
      }}
    >
      {children}
    </OrderDetailContext.Provider>
  );
};
