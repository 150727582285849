import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import MainRoutes from "./Routes";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <MainRoutes />
      <ToastContainer
        position="top-left"
        limit={3}
        autoClose={5000}
        pauseOnHover
        closeOnClick
      />
    </>
  );
}

export default App;
