import { VendorsOrderDetail } from "./order_detail.entity";

export interface VendorsOrder {
  id: number;
  consecutivo: number;
  nItems: number;
  valorTotal: number;
  numero_entrega: number;
  cantidad_pendiente: number;
  porcen_cant_depachado: number;
  porcen_cost_despachado: number;
  cantidad_aceptada: number;
  costo_cantidad_aceptada: number;
  costo_pendiente: number;
  items_dejados_vender: number;
  fecha_pedido: Date;
  fecha_despacho: Date;
  fecha_requerida: Date;
  fecha_acept: Date;
  catalogo: string;
  proveedor: string;
  coment_venda: string;
  coment_prov: string;
  factura: string;
  respon_aceptado: string;
  responsableVenda: string;
  entregaParciales: string;
  editable: string;
  estado: string;
  pareto: string;
  vxs: string;
  pocAgotados: string;
  porcCumplim: string;

  order_details: VendorsOrderDetail[];
  // order_deliveries: VendorsOrderDelivery[];
}

export enum OrderStatuses {
  DELETED = "Eliminado",
  CANCECLED = "Cancelado",
  SHIPPED = "Despachado",
  FINISHED = "Finalizado",
  DECLINED = "Declinado",
  ACCEPTED = "Aceptado",
  NEW = "Nuevo",
}
