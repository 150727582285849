import { toast } from "react-toastify";

const middleware = (axiosPromise) => {
  return axiosPromise.catch((error) => {
    if (error.response) {
      let token = localStorage.getItem("auth-token");
      if (token) {
        if (error.response?.data?.statusCode === 401) {
          let locale = window.location.pathname.replace(/^\/([^/]*).*$/, "$1");
          let destination = window.location.pathname.replace(
            /^\/.*\/([^/]*).*$/,
            "$1"
          );
          if (destination !== "login") {
            if (locale === "en" || locale === "es") {
              if (locale === "en") {
                toast.error("Session has expired");
              }

              if (locale === "es") {
                toast.error("La sesiónha expirado");
              }
              setTimeout(() => {
                window.location.href = `/${locale}/login`;
              }, 1000 * 3);
            } else {
              setTimeout(() => {
                window.location.href = `/en/login`;
              }, 1000 * 3);
            }
          }
        }
      } else {
        throw error;
      }

      // Request made and server responded
    } else if (error.request) {
      // The request was made but no response was received
      toast.error(
        "Ha ocurrido un error, por favor contacte con el administrador"
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error("Error de conexion");
    }
    return;
  });
};

export default middleware;
