/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react";
import { changeLanguage } from "../../resources/translation";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

const locales = [
  { id: "en", name: "English" },
  { id: "es", name: "Español" },
];

export default function Header({
  isAuthenticated,
  user,
}: {
  isAuthenticated: boolean;
  user: any;
}) {
  const [localeState, setLocaleState] = useState("en");
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const changeLocale = (e: any) => {
    setLocaleState(e.target.value);
    changeLanguage(e.target.value, navigate);
  };

  const hanldeLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (i18n.language === "es") {
      setLocaleState("es");
    }
    if (i18n.language === "en") {
      setLocaleState("en");
    }
  }, [location, i18n.language]);

  return (
    <div className="bg-white">
      <header className="relative">
        <nav aria-label="Top">
          {/* Top navigation */}
          <div className="bg-blue-header">
            <div className="max-w-screen-2xl mx-auto h-10 px-4 flex items-center justify-end sm:px-6 lg:px-8">
              {isAuthenticated && (
                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  <div className="flow-root">
                    <p className="text-md font-medium text-white hover:text-gray-100">
                      {user.nombre}
                    </p>
                  </div>
                </div>
              )}

              <form className="hidden lg:block ml-8">
                <div className="flex">
                  <label htmlFor="desktop-currency" className="sr-only">
                    {"locale.header.currency"}
                  </label>
                  <div className="-ml-2 group relative bg-gray-900 border-transparent rounded-md focus-within:ring-2 focus-within:ring-white">
                    <select
                      id="desktop-currency"
                      name="currency"
                      className="bg-none bg-white border-transparent rounded-md py-0.5 pl-2 pr-5 flex items-center text-sm font-medium text-gray-700 group-hover:text-gray-900 focus:outline-none focus:ring-0 focus:border-transparent"
                      onChange={changeLocale}
                      value={localeState}
                    >
                      {locales.map((local) => (
                        <option key={local.id} value={local.id}>
                          {local.name}
                        </option>
                      ))}
                    </select>
                    <div className="absolute right-0 inset-y-0 flex items-center pointer-events-none">
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        className="w-5 h-5 text-gray-300"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M6 8l4 4 4-4"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Secondary navigation */}
          <div className="bg-blue-header">
            <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="h-16 flex items-center justify-between">
                {/* Logo (lg+) */}
                <div className="hidden lg:mr-3 lg:flex lg:items-center xl:mr-20">
                  <a href="/">
                    <img
                      style={{ marginBottom: "36px", height: "6rem" }}
                      className="w-auto"
                      src="/logo-gdd.png"
                      alt="Great Deals Dist Logo"
                    />
                  </a>
                </div>
                <div className="flex-1 flex items-center justify-end">
                  <div className="flex items-center ml-8">
                    <div className="flow-root">
                      <a
                        href="/"
                        className="group -m-2 p-2 flex items-center"
                        onClick={() => {
                          hanldeLogout();
                        }}
                      >
                        <ArrowLeftOnRectangleIcon
                          className="flex-shrink-0 h-6 w-6 text-gray-200 group-hover:text-white"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
