import React from "react";
import { Navigate } from "react-router-dom";
import i18n from "../resources/translation";

export const ProtectedRoute = ({
  redirectPath = `/${i18n.language}/login`,
  children,
}: {
  redirectPath?: string;
  children?: React.ReactElement;
}) => {
  const token = localStorage.getItem("auth-token");
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
};
