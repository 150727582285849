import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputText from "./input";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { acceptOrder, declineOrder } from "../../../services/vendor/orders";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderDetailContext } from "../order_detail_context";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  labeled_for: yup.string().required(),
  comments: yup.string(),
});

export default function OrderDetailAccept() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const { order } = useContext(OrderDetailContext);

  const mapOrderDetails = () => {
    return order.order_details.map((order_detail) => {
      return {
        item: order_detail.item,
        quantity: order_detail.selectedQuantity || 0,
        cost: order_detail.item_product.Cost,
        discontinued: order_detail.discontinued,
      };
    });
  };

  const onsubmit = async (data: Record<string, string>, accepted: boolean) => {
    setLoading(true);

    const token = localStorage.getItem("auth-token");
    if (accepted) {
      const response = await acceptOrder(token, data, order.id);
      if (response) {
        toast.success(t("order_detail.succes_accept"));
        navigate("/");
      } else {
        toast.error(t("order_detail.error_accept_order"));
      }
    } else {
      const response = await declineOrder(token, data, order.id);
      if (response) {
        toast.success(t("order_detail.succes_decline"));
        navigate("/");
      } else {
        toast.error(t("order_detail.error_decline_order"));
      }
    }
    setLoading(false);
  };

  const onSubmitAccpet = (data: { [x: string]: any }) => {
    data.quantities = mapOrderDetails();
    onsubmit(data, true);
  };
  const onSubmitDecline = (data: { [x: string]: any }) => {
    data.quantities = mapOrderDetails();
    onsubmit(data, false);
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-center w-full">
        <div className="flex w-full">
          <InputText
            type="text"
            id="labeled_for"
            label={t("order_detail.labeled_for")}
            error={errors.labeled_for !== undefined}
            errorMessage={t("order_detail.labeled_error")}
            register={register}
          />
          <div className="w-full px-4 py-2">
            <label
              htmlFor="comments"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t("order_detail.comments")}
            </label>
            <textarea
              {...register("comments")}
              rows={3}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={""}
            />
          </div>
        </div>
        <div className="flex">
          <button
            type="button"
            className="xs:w-60 m-1 flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-150"
            onClick={handleSubmit(onSubmitAccpet)}
            disabled={loading}
          >
            <CheckCircleIcon className="h-6 w-6 mr-2 text-white" />
            {t("order_detail.accpet_order")}
          </button>
          <button
            type="button"
            className="xs:w-60 m-1 flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-red-600 hover:bg-red-700 transition ease-in-out duration-150"
            onClick={handleSubmit(onSubmitDecline)}
            disabled={loading}
          >
            <XCircleIcon className="h-6 w-6 mr-2 text-white" />
            {t("order_detail.decline_order")}
          </button>
        </div>
      </div>
    </>
  );
}
