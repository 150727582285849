import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrderStatuses } from "../../entities/order.entity";
import {
  VendorsOrderDetailProduct,
  VendorsOrderDetailSize,
} from "../../entities/order_detail.entity";
import { OrderDetailContext } from "./order_detail_context";
import ProductImage from "./product_image";
import SizeSelector from "./size_selector/size_selector";

export default function Product({
  product,
  readOnly,
}: {
  product: VendorsOrderDetailProduct;
  readOnly: boolean;
}) {
  const { t } = useTranslation();
  const { order, setDiscontinuedDetails } = useContext(OrderDetailContext);
  const [discontinued, setDiscontinued] = useState<boolean>(false);
  const [orderDetailSizes, setOrderDetailSizes] = useState<
    VendorsOrderDetailSize[]
  >([]);

  const mapProductSizes = () => {
    return product.sizes.map((x) => {
      const size = {
        id: x.item.item,
        primaryText: x.item.Talla,
        isSelected: product.selectAll || x.isSelected || false,
        price: x.item.Cost,
        discontinued: discontinued,
        maxSelectQuantity: x.quantity_pending,
        selectedQuantity: product.selectAll
          ? x.selectedQuantity != undefined && x.selectedQuantity >= 0
            ? x.selectedQuantity
            : x.quantity_pending
          : x.selectedQuantity || 0,
      };
      if (order.estado === OrderStatuses.NEW) {
        return {
          ...size,
          secondaryText: {
            info: `${t("order_detail.quantity_ordered")}`,
            value: x.quantity_ordered,
          },
          maxSelectQuantity: x.quantity_ordered,
          selectedQuantity: product.selectAll
            ? x.selectedQuantity
              ? x.selectedQuantity
              : x.quantity_ordered
            : x.selectedQuantity || 0,
        };
      }
      if (order.estado === OrderStatuses.ACCEPTED) {
        return {
          ...size,
          secondaryText: {
            info: `${t("order_detail.quantity_accepted")}`,
            value: x.quantity_pending,
          },
        };
      }
      if (order.estado === OrderStatuses.SHIPPED && order.entregaParciales) {
        return {
          ...size,
          secondaryText: {
            info: `${t("order_detail.quantity_pending")}`,
            value: x.quantity_pending,
          },
        };
      }

      return {
        ...size,
        secondaryText: {
          info: `${t("order_detail.quantity_pending")}`,
          value: x.quantity_pending,
        },
      };
    });
  };

  useEffect(() => {
    const productSizes = mapProductSizes();
    if (setOrderDetailSizes) {
      setOrderDetailSizes(productSizes);
    }
  }, [product]);
  useEffect(() => {
    const productSizes = mapProductSizes();
    if (setDiscontinuedDetails) {
      setDiscontinuedDetails(productSizes);
    }
  }, [discontinued]);
  return (
    <li className="flex xs:flex-col lg:flex-row xs:items-center py-1 sm:py-1">
      <div className="flex-shrink-0">
        <div
          className={"relative rounded-lg object-center object-cover w-52 h-44"}
        >
          <div>
            <ProductImage imgSrc={product.MPN} />
          </div>
        </div>
      </div>

      <div className="relative ml-4 flex-1 flex justify-center flex-col lg:ml-6">
        <div className={"flex w-full xs:flex-col lg:flex-row justify-between"}>
          <div className="text-lg font-medium xs:w-full lg:w-max text-gray-700 hover:text-gray-800">
            <p>{product.Description.split(" Size")[0]}</p>
          </div>
          <div className="flex justify-end xs:flex-col-reverse">
            <div className="relative flex items-start mt-2 mr-10">
              <div className="flex h-5 items-center">
                <input
                  id={`discontinued_${product.id}`}
                  name={`discontinued_${product.id}`}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={(e) => {
                    setDiscontinued(e.target.checked);
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={`discontinued_${product.id}`}
                  className="font-medium text-gray-700"
                >
                  {t("order_detail.discontinued")}
                </label>
              </div>
            </div>
            <div className="text-lg font-medium xs:text-gray-500 lg:text-gray-700 hover:text-gray-800">
              {product.coleccion}
            </div>
          </div>
        </div>
        {/*SIzes Selection */}
        <div className="mt-1 flex items-center">
          <SizeSelector sizes={orderDetailSizes || []} readOnly={readOnly} />
        </div>
      </div>
    </li>
  );
}
