import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputText from "./input";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { finishOrder } from "../../../services/vendor/orders";
import { useNavigate } from "react-router-dom";
import { OrderDetailContext } from "../order_detail_context";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatCurrency } from "../../../common/formatCurrency";

const validationSchema = yup.object({
  labeled_for: yup.string().required(),
  comments: yup.string(),
});

export default function OrderDetailFinish() {
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const { order } = useContext(OrderDetailContext);

  const mapOrderDetails = () => {
    return order.order_details.map((order_detail) => {
      return {
        discontinued: order_detail.discontinued,
        item: order_detail.item,
        quantity: order_detail.selectedQuantity,
        cost: order_detail.item_product.Cost,
      };
    });
  };

  useEffect(() => {
    const { quantity, cost } = order.order_details.reduce(
      (acum, detail) => {
        acum.cost += detail.item_product.Cost * (detail.selectedQuantity ?? 0);
        acum.quantity += detail.selectedQuantity ?? 0;
        return acum;
      },
      {
        quantity: 0,
        cost: 0,
      }
    );
    setTotalQuantity(quantity);
    setTotalCost(cost);
  }, [order]);

  const onsubmit = async (data: Record<string, string>, finish: boolean) => {
    setLoading(true);
    const token = localStorage.getItem("auth-token");
    if (finish) {
      const response = await finishOrder(token, data, order.id);
      if (response) {
        toast.success(t("order_detail.succes_finish"));
        navigate("/");
      } else {
        setLoading(false);
        toast.error(t("order_detail.error_finish_order"));
      }
    } else {
      setLoading(false);
      navigate("/");
    }
  };

  const onSubmitFinish = (data: { [x: string]: any }) => {
    data.quantities = mapOrderDetails();
    onsubmit(data, true);
  };
  const onSubmitCancel = (data: { [x: string]: any }) => {
    onsubmit(data, false);
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-center w-full">
        <div className="flex w-full flex-wrap">
          <InputText
            type="text"
            id="labeled_for"
            label={t("order_detail.labeled_for")}
            error={errors.labeled_for !== undefined}
            errorMessage={t("order_detail.labeled_error")}
            register={register}
          />
          <InputText
            type="text"
            id="billing_number"
            label={t("order_detail.billing_number")}
            error={errors.labeled_for !== undefined}
            errorMessage={t("order_detail.billing_number_error")}
            register={register}
          />
          {order.entregaParciales === "1" && (
            <div className="w-full lg:w-1/2 px-4 py-2 flex items-center justify-start">
              <input
                {...register("finished")}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor="finished"
                className="block text-sm font-medium text-gray-700 ml-4"
              >
                {t("order_detail.finished")}
              </label>
              <p className="text-gray-500 text-sm ml-4">
                {t("order_detail.finished_explanation")}
              </p>
            </div>
          )}
          <div className="w-full lg:w-1/2 px-4 py-2">
            <label
              htmlFor="comments"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t("order_detail.comments")}
            </label>
            <textarea
              {...register("comments")}
              rows={3}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={""}
            />
          </div>
        </div>
        <div className="flex flex-col" style={{ minWidth: "300px" }}>
          <div className="w-full px-4 py-2">
            <p>
              <strong>{t("order_detail.totals_summary")}</strong>
            </p>
            <p>
              <span>{t("order_detail.current_quantity")}: </span>
              <span>
                <strong> {formatCurrency(totalQuantity)}</strong>
              </span>
            </p>
            <p>
              <span>{t("order_detail.current_cost")}: </span>
              <span>
                <strong> $ {formatCurrency(totalCost)}</strong>
              </span>
            </p>
          </div>
          <button
            id="confirm_order"
            type="button"
            className="xs:w-60 m-1 flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-150"
            onClick={handleSubmit(onSubmitFinish)}
            disabled={loading}
          >
            {loading === true ? (
              <>{t("order_detail.finish_order_loading")}</>
            ) : (
              <>
                <CheckCircleIcon className="h-6 w-6 mr-2 text-white" />
                {t("order_detail.finish_order")}
              </>
            )}
          </button>
          <button
            id="cancel_order_confirmation"
            type="button"
            className="xs:w-60 m-1 flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-red-600 hover:bg-red-700 transition ease-in-out duration-150"
            onClick={handleSubmit(onSubmitCancel, onSubmitCancel)}
            disabled={loading}
          >
            <XCircleIcon className="h-6 w-6 mr-2 text-white" />
            {t("order_detail.cancel_order")}
          </button>
        </div>
      </div>
    </>
  );
}
