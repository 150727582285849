import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";
import qs from "querystring";

export const getOrders = async (token, pagination, searchText) => {
  const filters = {
    order_id: searchText,
    invoice_id: searchText,
  };
  const response = await middleware(
    axios.get(`${baseURL}/vendor/orders?${qs.stringify(filters)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
export const getOrder = async (token, orderId) => {
  const response = await middleware(
    axios.get(`${baseURL}/vendor/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
export const getInvoices = async (token, orderId) => {
  const response = await middleware(
    axios.get(`${baseURL}/vendor/orders/invoices/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
export const getOrderExportWithDetails = async (token, orderId) => {
  const response = await middleware(
    axios.get(`${baseURL}/vendor/orders/export_details/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
  );
  return response.data;
};
export const getExportInvoice = async (token, orderId, deliveryId) => {
  const response = await middleware(
    axios.get(
      `${baseURL}/vendor/orders/export_invoice/${orderId}/${deliveryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    )
  );
  return response.data;
};
export const getExportDelivery = async (token, orderId, deliveryId) => {
  const response = await middleware(
    axios.get(
      `${baseURL}/vendor/orders/export_delivery/${orderId}/${deliveryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    )
  );
  return response.data;
};
export const getOrderPrintVersion = async (token, orderId) => {
  const response = await middleware(
    axios.get(`${baseURL}/vendor/orders/print_version/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
  );
  return response.data;
};
export const acceptOrder = async (token, data, orderId) => {
  const response = await middleware(
    axios.post(`${baseURL}/vendor/orders/accept/${orderId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
export const declineOrder = async (token, data, orderId) => {
  const response = await middleware(
    axios.post(`${baseURL}/vendor/orders/decline/${orderId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};

export const finishOrder = async (token, data, orderId) => {
  const response = await middleware(
    axios.post(`${baseURL}/vendor/orders/finish/${orderId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
