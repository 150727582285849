import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading/loading";
import { OrderStatuses, VendorsOrder } from "../entities/order.entity";
import { getInvoices, getOrders } from "../services/vendor/orders";
import { Buffer } from "buffer";
import InvoicesPopup from "../components/dashboard/invoices";
import { formatCurrency } from "../common/formatCurrency";

const dateFormat = "MM/dd/yyyy";

export default function Dashboard() {
  const [orders, setOrders] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [count] = useState(0);
  const [dataTable, setDataTable] = useState<any>([]);
  const [currentInvoiceOrder, setCurrentInvoiceOrderId] = useState<number>(0);
  const [invoices, setInvoices] = useState<any>([]);
  const [showInvoicesPopup, setShowInvoicesPopup] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getSearchPaginated = async (newPage = 0) => {
    setShowLoading(true);
    if (newPage === 0) {
      setPage(1);
      newPage = 1;
    }

    const token = localStorage.getItem("auth-token");
    const paginatinoData = {
      _limit: 10,
      _offset: newPage * 10,
    };
    const ordersResponse = await getOrders(token, paginatinoData, searchText);
    if (newPage === 1) {
      setOrders([...ordersResponse]);
    } else {
      setOrders([...orders, ...ordersResponse]);
    }
    // setCount(responseCount);
    setPage(newPage);
    setShowLoading(false);
  };

  const loadMore = () => {
    getSearchPaginated(page + 1);
  };

  const handleDetail = async (
    number: number,
    status: string,
    partial: "SI" | "NO",
    pending_quantity: number
  ) => {
    if (status === OrderStatuses.NEW) {
      navigate(`order/${number}`);
      return;
    }
    if (status === OrderStatuses.ACCEPTED) {
      navigate(`order/finish/${number}`);
      return;
    }
    if (pending_quantity <= 0) {
      navigate(`order/read_only/${number}`);
    }
    if (status === OrderStatuses.SHIPPED && partial === "SI") {
      navigate(`order/finish/${number}`);
      return;
    }

    navigate(`order/read_only/${number}`);
  };

  const getDetailButtonColorClass = (
    status: string,
    partial: "SI" | "NO",
    pending_quantity: number
  ) => {
    if (status === OrderStatuses.NEW)
      return "bg-green-600 hover:bg-green-700 focus:ring-green-500";
    if (pending_quantity <= 0) {
      return "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500";
    }
    if (
      status === OrderStatuses.ACCEPTED ||
      (status === OrderStatuses.SHIPPED && partial === "SI")
    )
      return "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
    return "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500";
  };
  const getDetailButtonText = (
    status: string,
    partial: "SI" | "NO",
    pending_quantity: number
  ) => {
    if (status === OrderStatuses.NEW) return t("order_history.accept_order");
    if (pending_quantity <= 0) {
      return t("order_history.show_detail");
    }
    if (
      status === OrderStatuses.ACCEPTED ||
      (status === OrderStatuses.SHIPPED && partial === "SI")
    )
      return t("order_history.ship_order");
    return t("order_history.show_detail");
  };

  const handleDataTableMap = async () => {
    const dataTableMap = orders.map((order: VendorsOrder) => ({
      order: order.id,
      collection: order.catalogo,
      date: format(new Date(order.fecha_pedido), dateFormat),
      comment: Buffer.from(order.coment_venda, "ascii").toString("utf-8"),
      delivery: order.numero_entrega,
      items: order.nItems,
      total_ammount: order.valorTotal,
      responsible: order.responsableVenda,
      partial_deliveries: order.entregaParciales != "0" ? "SI" : "NO",
      editable: order.editable != "0" ? "SI" : "NO",
      state: order.estado,
      pending_quantity: order.cantidad_pendiente,
      dispatched_percent: `${order.porcen_cant_depachado.toFixed(2)} %`,
      dispatched_cost: `${order.porcen_cost_despachado.toFixed(2)} %`,
    }));
    setDataTable(dataTableMap);
  };

  const handleInvoices = async (orderId: number) => {
    setShowLoading(true);
    const token = localStorage.getItem("auth-token");
    const invoicesResponse = await getInvoices(token, orderId);
    if (invoicesResponse) {
      setCurrentInvoiceOrderId(orderId);
      setInvoices(invoicesResponse);
      setShowInvoicesPopup(true);
    }
    setShowLoading(false);
  };

  useEffect(() => {
    getSearchPaginated(page);
  }, [page]);
  useEffect(() => {
    handleDataTableMap();
  }, [orders]);

  return (
    <>
      <div
        className="bg-white overflow-auto"
        style={{ height: "calc(100vh - 104px)" }}
      >
        <div className="mx-auto py-8 px-6 py-8">
          <div className={`flex justify-end items-center mb-5`}>
            <h1
              className={`text-2xl font-extrabold tracking-tight text-gray-900 mb-2`}
            >
              {t("order_history.search")}
            </h1>
            <div className="flex rounded-md shadow-sm ml-0 ml-5">
              <div
                className={
                  "z-0 flex w-full items-center border border-gray-400 rounded-lg"
                }
              >
                <input
                  style={{ outline: "none" }}
                  className="ml-3 w-full rounded-lg h-10 outline-0 outline-transparent"
                  placeholder={t("order_history.search_placeholder")}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={async (e) => {
                    if (e.code === "Enter") {
                      await getSearchPaginated(0);
                    }
                  }}
                />
                <div className="inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <button
                    type="submit"
                    className="text-gray-700 hover:text-gray-500"
                    onClick={async () => {
                      await getSearchPaginated(0);
                    }}
                  >
                    <MagnifyingGlassIcon
                      className="w-8 h-8"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.show_detail")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.invoices")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.order")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.collection")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.date")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-40"
                  >
                    {t("order_history.comment")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.delivery")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.items")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.total_ammount")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.responsible")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.partial_deliveries")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.editable")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.pending_quantity")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.dispatched_percent")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("order_history.dispatched_cost")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((rowData: any, dataIdx: number) => (
                  <tr
                    key={rowData.order}
                    className={`${
                      dataIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div
                        data-cy={`order_detail_${rowData.order}`}
                        onClick={() => {
                          handleDetail(
                            rowData.order,
                            rowData.state,
                            rowData.partial_deliveries,
                            rowData.pending_quantity
                          );
                        }}
                        className={`
                        w-full flex justify-center 
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                        border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white 
                        cursor-pointer
                        ${getDetailButtonColorClass(
                          rowData.state,
                          rowData.partial_deliveries,
                          rowData.pending_quantity
                        )}
                        `}
                      >
                        {getDetailButtonText(
                          rowData.state,
                          rowData.partial_deliveries,
                          rowData.pending_quantity
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div
                        onClick={() => {
                          handleInvoices(rowData.order);
                        }}
                        className={`
                        w-full flex justify-center 
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                        border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white 
                        bg-gray-600 hover:bg-gray-700 focus:ring-gray-500
                        cursor-pointer
                        `}
                      >
                        {t("order_detail.invoices")}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.order}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.collection}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.date}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      <p style={{ minWidth: "200px", maxWidth: "300px" }}>
                        {rowData.comment}
                      </p>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.delivery}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.items}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      $ {formatCurrency(rowData.total_ammount)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.responsible}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.partial_deliveries}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.editable}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.pending_quantity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.dispatched_percent}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rowData.dispatched_cost}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showLoading === true ? <Loading /> : null}
            {orders.length > 0 && count > orders.length && (
              <div className={"flex w-full pt-6 pb-6 justify-center"}>
                <button
                  type={"button"}
                  className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-3
                px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  onClick={() => loadMore()}
                >
                  {t("order_history.load_more_orders")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <InvoicesPopup
        rows={invoices}
        open={showInvoicesPopup}
        orderId={currentInvoiceOrder}
        setOpen={setShowInvoicesPopup}
      />
    </>
  );
}
