import React, { useContext } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { formatCurrency } from "../../common/formatCurrency";
import { OrderDetailContext } from "../order_detail/order_detail_context";
import { Download } from "../../common/download";
import {
  getExportDelivery,
  getExportInvoice,
} from "../../services/vendor/orders";

export default function InvoicesPopup({
  rows,
  open,
  orderId,
  setOpen,
}: {
  rows: Array<any>;
  open: boolean;
  orderId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const handlePrintInvoice = async (deliveryId: number) => {
    const token = localStorage.getItem("auth-token");
    const orderPrintWithDetailsRespnose = await getExportInvoice(
      token,
      orderId,
      deliveryId
    );
    // eslint-disable-next-line no-debugger
    Download(
      orderPrintWithDetailsRespnose,
      `factura_pedido_${orderId}_entrega_${deliveryId}.xls`
    );
  };
  // const handlePrintVersion = async (deliveryId: number) => {
  //   const token = localStorage.getItem("auth-token");
  //   const orderPrintWithDetailsRespnose = await getExportDelivery(
  //     token,
  //     orderId,
  //     deliveryId
  //   );
  //   // eslint-disable-next-line no-debugger
  //   Download(
  //     orderPrintWithDetailsRespnose,
  //     `Rayado-Pedido-Detalles-${orderId}-entrega-${deliveryId}.xls`
  //   );
  // };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-fit sm:m-4 sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start overflow-x-auto">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("order_history.invoices")}
                    </Dialog.Title>
                    <div className="mt-2">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("invoices.delivery_number")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("invoices.delivery_date")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("invoices.invoice_number")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("invoices.delivery_quantity")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("invoices.delivery_cost")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("invoices.options")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((rowData, dataIdx) => {
                            return (
                              <tr
                                key={rowData.numero_entrega}
                                className={`${
                                  dataIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                                }`}
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {rowData.numero_entrega}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {format(
                                    new Date(rowData.fecha_ingreso_rayado),
                                    "MM/dd/yyyy"
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {rowData.numero_factura}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {rowData.total_unidades_entregadas}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  ${" "}
                                  {formatCurrency(
                                    rowData.valor_total_unidades_entregadas
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div className="flex">
                                    <div
                                      onClick={() => {
                                        handlePrintInvoice(
                                          rowData.numero_entrega
                                        );
                                      }}
                                      className={`
                                    flex justify-center cursor-pointer
                                    mr-2
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                                    border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white 
                                    bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500
                                    `}
                                    >
                                      {t("order_detail.export_details")}
                                    </div>
                                    {/* <div
                                      onClick={() => {
                                        handlePrintVersion(
                                          rowData.numero_entrega
                                        );
                                      }}
                                      className={`
                                    flex justify-center cursor-pointer
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                                    border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white 
                                    bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500
                                    `}
                                    >
                                      {t("order_detail.print_version")}
                                    </div> */}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
