import React from "react";
import { Navigate } from "react-router-dom";

export default function ExtLogin() {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("auth_token");
  if (token) {
    localStorage.setItem("auth-token", token);
    return (
      <>
        <Navigate to={"/en/"} />
      </>
    );
  }

  return (
    <>
      <p>Error</p>
    </>
  );
}
