import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export default function ProductImage({ imgSrc }: { imgSrc: string }) {
  if (imgSrc) {
    return (
      <div className="flex-shrink-0">
        <div
          className={"relative rounded-lg object-center object-cover w-52 h-44"}
        >
          <TransformWrapper>
            {() => (
              <div className="flex justify-center">
                <TransformComponent>
                  <img
                    src={`https://service.greatdealsdist.com/api/image/small/${imgSrc}?ext=jpg`}
                    alt=""
                  />
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex-shrink-0">
        <div
          className={"relative rounded-lg object-center object-cover w-52 h-44"}
        >
          <img
            style={{ marginBottom: "36px", height: "6rem" }}
            className="w-auto"
            src="/logo-gdd.png"
            alt="Great Deals Dist Logo"
          />
        </div>
      </div>
    );
  }
}
